import React from "react"
import { graphql } from "gatsby"

import Header from "../components/MainPage/Header"
import Footer from "../components/MainPage/Footer"

export default function blogPost({ data }) {
    // console.log(typeof data.html, data)
    // let html = data.html
    return(
        <div>
            <Header />
            <div className="blogContent" dangerouslySetInnerHTML={{ __html:data.markdownRemark.html }} />
            <Footer />
        </div>
    )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`